import React from 'react'
import { Button, ButtonGroup, Col, Message, Tooltip, useToaster, Whisper } from 'rsuite'
import { Colors, mainColors } from './theme/Colors'
import Shadows from './theme/Shadows'

const GridElement = ({ lg, md, img, open, index, makeCurrent }) => {

  const toaster = useToaster()
  const download = e => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("button");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      }).finally(() => {
        toaster.push(<Message showIcon type='info'>
          You have downloaded an image
        </Message>, {placement: 'bottomCenter'})
        window.setTimeout(() => toaster.clear(), 10000)
      })
  };


    const tooltip = (txt) => {
        return (
            <Tooltip>
                {txt}
            </Tooltip>
        )
    }
    return (
        <Col lg={lg} md={md} sm={24} xs={24} className='mb-10' style={{ position: 'relative' }}>
            <img src={img} alt="Placeholder" width={100 + '%'} height={index > 5 ? 900 : 500} />
            <div style={styles.overlay}>
                <ButtonGroup style={styles.btnWrap}>
                    <Whisper trigger='hover' speaker={tooltip('View image in full size')} placement='top'>
                        <Button
                        onClick={() => {
                          open()
                          makeCurrent(index)
                        }}
                        appearance='primary'
                        color='red'
                        style={styles.mainBtn}
                        size='lg'
                        >
                            Preview
                        </Button>
                    </Whisper>
                    <Whisper trigger='hover' speaker={tooltip('Click to download photo')} placement='top' >
                        <Button
                        as={"a"}
                        appearance='ghost'
                        color='red'
                        size='lg'
                        href={img}
                        download
                        style={styles.secBtn}
                        onClick={e => download(e)}
                        >
                            Download
                        </Button>
                    </Whisper>
                </ButtonGroup>
            </div>
        </Col>
    )
}

const styles = {
    overlay: {
        position: 'absolute',
        top: 0, bottom: 50,
        left: 0, right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    btnWrap: {
        backgroundColor: 'rgba(0,0,0, .25)',
        boxShadow: Shadows.main,
    },
    mainBtn: {
        backgroundColor: mainColors.red,
    },
    secBtn: {
        color: Colors.white,
        border: '1px solid' + mainColors.red,
    }
}

export default GridElement
