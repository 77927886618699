/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import { useParams } from 'react-router-dom'
import { Button, Loader } from 'rsuite'
import { mainColors } from '../../../components/theme/Colors'
import { getCurrentEvent } from '../../../Firebase'
import Location from '@rsuite/icons/Location'
import Time from '@rsuite/icons/TimeRound'
import '../../../styles/event-details.scss'

const EventDetailsPage = () => {
    const { eventId } = useParams()
    const [event,setEvent] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
      getCurrentEvent(eventId, setEvent, setLoading)
    }, [eventId])
    return (
        <>
            <div style={styles.pageWrap} className='event-details-page'>
              {loading ? (<Loader content='One moment please' size='lg' speed='slow' vertical/>) :
              event === null ? null : (<div className='inner'>
              <img
              src={event.image_link}
              alt={`A photo of the location for the following event: ${event.name}.`}
              className='event-img'
              />
              <div className="information">
                <h1 className='name'>{event.name}</h1>
                <h2 className='introduction'>{event.short_description}</h2>
                <h3 className="description">{event.description}</h3>
                <h4 className="time"><Time/> Time of event: {event.time}</h4>
                <h4 className="location"><Location/> Event location: {event.location}</h4>
                <Button as={'a'} href={event.event_official_link} target='_blank' rel='norefferer'
                appearance='primary' color='red' style={styles.mainBtn}>
                    Buy tickets
                </Button>
              </div>
              </div>)}
            </div>
        </>
    )
}

const styles = {
    pageWrap: {
        paddingTop: 7.5 + 'rem',
        paddingBottom: 7.5 + 'rem',
        paddingLeft: 2 + 'rem',
        paddingRight: 2 + 'rem',
    },
    mainBtn: {
        backgroundColor: mainColors.red,
    },
}

export default EventDetailsPage
