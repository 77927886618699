import React from 'react';
import { Routes, Route } from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import './styles/main.scss';

import HomePage from './pages/home/Index';
import About from './pages/about/Index';
import EventsPage from './pages/events/Index';
import GalleryPage from './pages/gallery/Index';
import VideosPage from './pages/videos/Index';
import EventDetailsPage from './pages/events/details';
import MainLayout from './pages/layouts/MainLayout';
//import AdminPage from './backend';
//import AdminIndexPage from './backend/components/pages/IndexPage';
//import ManageProjectsPage from './backend/components/pages/projects/ManageProjectsPage';
//import ManageProjectsStartPage from './backend/components/pages/projects/ManageProjectsStartPage';
//import EditProjectPage from './backend/components/pages/projects/EditProjectPage';
//import ManageOrdersPage from './backend/components/pages/orders/ManageOrdersPage';
//import ManageOrdersIndexPage from './backend/components/pages/orders/ManageOrdersIndexPage';
//import OrderDetailsPage from './backend/components/pages/orders/OrderDetailsPage';
//import ManageEventsPage from './backend/components/pages/events/ManageEventsPage';
//import ManageEventsIndexPage from './backend/components/pages/events/ManageEventsIndexPage';
//import CreateEventPage from './backend/components/pages/events/CreateEventPage';
//import EditEventPage from './backend/components/pages/events/EditEventPage';
import ScrollToTop from './ScrolltoTop';
import ProjectsPage from './pages/projects/Index';
import PrivacyPolicy from './pages/privacy-policy/Index';
import ImprintPage from './pages/privacy-policy/Imprint';

function App() {
  return (
    <>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<MainLayout/>}>
          <Route index element={<HomePage />} />
          <Route path='about' element={<About />} />
          <Route path='projects' element={<ProjectsPage/>} />
          <Route path='events' element={<EventsPage />} />
          <Route path='events/:eventId' element={<EventDetailsPage />} />
          <Route path='gallery' element={<GalleryPage />} />
          <Route path='videos' element={<VideosPage />} />
          <Route path='privacy-policy' element={<PrivacyPolicy/>}/>
          <Route path='imprint' element={<ImprintPage/>}/>
          </Route>
          {/* Admin}
          <Route path='/admin' element={<AdminPage />}>
            <Route index element={<AdminIndexPage/>}/>
            <Route path='projects' element={<ManageProjectsPage/>}>
              <Route index element={<ManageProjectsStartPage/>}/>
              <Route path=':projectId' element={<EditProjectPage/>} />
            </Route>
            <Route path='orders' element={<ManageOrdersPage/>}>
              <Route index element={<ManageOrdersIndexPage/>}/>
              <Route path=':orderId' element={<OrderDetailsPage/>}/>
            </Route>
            <Route path='events' element={<ManageEventsPage/>}>
              <Route index element={<ManageEventsIndexPage/>}/>
              <Route path='create' element={<CreateEventPage/>}/>
              <Route path=':eventId' element={<EditEventPage/>}/>
            </Route>
  // </Route> */}
        </Routes>
      </ScrollToTop>
    </>
  );
}

export default App;
