import React from 'react'
import { useMediaQuery } from '../../components/misc/custom-hooks'
import Bio from './components/Bio'
import Events from './components/Events'
import Hero from './components/Hero'

const Home = () => {
    const isMobile = useMediaQuery('(max-width: 992px)')
    return (
        <>
            <Hero isMobile={isMobile} />
            <Bio isMobile={isMobile} />
            <Events isMobile={isMobile} />
        </>
    )
}

export default Home
