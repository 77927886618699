import React from 'react'
import { IconButton, Loader } from 'rsuite'
import ModalBody from 'rsuite/esm/Modal/ModalBody'
import LeftIcon from '@rsuite/icons/legacy/ArrowCircleLeft'
import RightIcon from '@rsuite/icons/legacy/ArrowCircleRight'
import IMG1 from './images/_MG_4760.jpg'
import IMG2 from '../assets/paolopiatto.jpg'
import IMG3 from './images/Maybe.jpg'
import IMG4 from './images/IMG_1214.jpg'
import IMG5 from './images/Nuova_Orchestra_Italiana.jpg'
import IMG6 from './images/Paolo_Eleodori.jpg'
import IMG7 from '../assets/Paolo-Ulrich01.jpg'
import IMG8 from '../assets/Paolo-Ulrich02.jpg'
import { useMediaQuery } from './misc/custom-hooks'

const IMAGES = [
    {
        index: 0,
        name: 'img1',
        url: IMG1,
        lg: 14,
        md: 16,
    },
    {
        index: 1,
        name: 'img2',
        url: IMG2,
        lg: 10,
        md: 8,
    },
    {
        index: 2,
        name: 'img3',
        url: IMG3,
        lg: 8,
        md: 8,
    },
    {
        index: 3,
        name: 'img4',
        url: IMG4,
        lg: 16,
        md: 16,
    },
    {
        index: 4,
        name: 'img5',
        url: IMG5,
        lg: 16,
        md: 16,
    },
    {
        index: 5,
        name: 'img6',
        url: IMG6,
        lg: 8,
        md: 8,
    },
    {
      index: 6,
      name: 'img7',
      url: IMG7,
      lg: 12,
      md: 12,
    },
    {
      index: 7,
      name: 'img8',
      url: IMG8,
      lg: 12,
      md: 12,
    },
]

const ImageModalBody = ({ index, goLeft, goRight, loading }) => {

    const isMobile = useMediaQuery('(max-width: 992px)')
    const styles = {
        sliderWrap: {
            overflow: 'hidden',
            flex: 1,
            justifyContent: 'center',
            aligItems: 'center',
            width: '100%',
            height: 650,
        },
        sliderSelf: {
            display: 'flex',
            flexWrap: 'no-wrap',
            transform: `translateX(-${index * 100}%)`,
            alignItems: 'center',
        },
        imageWrap: {
            width: 100 + '%',
            flexShrink: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
        },
        leftBtn: {
            position: 'absolute',
            height: 50,
            width: 50,
            fontSize: 30,
            top: 'calc(50% - 25px)',
            left: 25,
        },
        rightBtn: {
            position: 'absolute',
            height: 50,
            width: 50,
            fontSize: 30,
            top: 'calc(50% - 25px)',
            right: 25,
        },

    }

    return (
        <ModalBody>
            {
                loading ? (
                    <Loader center size='md' speed='normal' content='Loading...' />
                ) : (
                    <div style={styles.sliderWrap} className='hide-scrollbar'>
                        <div style={styles.sliderSelf}>
                            {IMAGES.map(el => (
                                <div style={styles.imageWrap}>
                                    <img src={el.url} alt={el.name} width='100%' height={650} className='modal-image'/>
                                    <IconButton disabled={index === 0} style={styles.leftBtn} icon={<LeftIcon />} onClick={goLeft} />
                                    <IconButton disabled={index === 7} style={styles.rightBtn} icon={<RightIcon />} onClick={goRight} />
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
        </ModalBody>
    )
}

export default ImageModalBody
