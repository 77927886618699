import React from 'react'
import { Button, Input, Message, Modal, useToaster } from 'rsuite'
import { mainColors } from './theme/Colors'
import './styles/purchase-modal.scss'
import { createOrder } from '../Firebase'

const PurchaseModal = ({open, close, project}) => {
  const toaster = useToaster()
  const [name, setName] = React.useState('')
  const [mail, setMail] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [message, setMessage] = React.useState('')

  const orderAlbum = () => {
    const statements = {
      noName: name === '',
      falseName: name.split(' ').length < 2,
      noMail: mail === '' ,
      falseMail: !(mail.split('@').length === 2) && (mail.split('.').length < 2),
      noAddress: address === '',
    }
    const errorMessage =
      statements.noName ? 'Please enter your name to order.' :
      statements.falseName ? 'Please write your full name.' :
      statements.noMail ? 'Please write your email address to continue.' :
      statements.falseMail ? `${mail} does not follow the format of "email@address.com".` :
      'Please write your address to order.'
      if (statements.noName || statements.falseName || statements.noMail
        || statements.falseMail || statements.noAddress) {
          toaster.push(<Message showIcon type='error'>
            {errorMessage}
          </Message>, {placement: 'bottomCenter'})
          window.setTimeout(() => toaster.clear(), 10000)
        } else {
          createOrder(project.id, name, mail, address, message)
          toaster.push(<Message showIcon type='success'>
            Your order has been recieved! <br/>
            You will recieve an email about your order in the next 24 hours.
          </Message>, {placement: 'bottomCenter'})
          window.setTimeout(() => toaster.clear(), 10000)
        }

  }


  return (
    <Modal open={open} onClose={close} className='purchase-modal'>
      <Modal.Header className='modal-header'>
        <Modal.Title className='title'>
          Order Albums
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body'>
        <h1 className='title'>{project.title}</h1>
        <p className="introduction">
          Songs: {project.songs.length} songs. <br/>
          length: {project.time} minutes.
        </p>
        <div className="make-order-con">
          <p className="instructions">
            Write your full name, email address & physical address below. <br/>
            Then you will recieve a reciept in the next 24 hours.
          </p>
          <div className="form-con">
            <div className="form-element">
              <label className="label">Full name</label>
              <Input placeholder='Write your full name here.' onChange={setName}/>
            </div>
            <div className="form-element">
              <label className="label">Email address</label>
              <Input placeholder='Write your email address here.' onChange={setMail}/>
            </div>
            <div className="form-element">
              <label className="label">Address</label>
              <Input placeholder='Write your address here.' onChange={setAddress}/>
            </div>
            <div className="form-element">
              <label className="label">Extra notes</label>
              <Input
              as={'textarea'}
              rows={5}
              placeholder='Write anything you would like to add here.'
              onChange={setMessage}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
        appearance='primary'
        style={styles.btn}
        onClick={orderAlbum}
        >
          Order
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const styles = {
  btn: {
    backgroundColor: mainColors.red
  }
}

export default PurchaseModal
