import React from 'react'
import PAOLO from '../../../components/images/Konzert+HN+Holding+23_09_21-3915.jpg'
import '../../../styles/about.scss'

const Hero = ({ isMobile }) => {
    return (
        <>
            <div className={`${isMobile ? 'padded' : 'p-50'} bg-grey about-page-info`}>
                <img src={PAOLO} alt="Placeholder" width={100 + '%'} height={600} className='mt-5 shadow' />
                <h1 className='mt-5 about-title'>
                    About Paolo
                </h1>
                <p className='mt-3 about-content'>
                    Born in 1965 in Pisa, he started playing drums at the age of 14,
                    initially as an autodidact, later he received lessons from Carlo Cavallini in Livorno.

                    In 1987 he came to Berlin,
                    where he continued his percussion studies with Michael Landmesser Sdrena
                    and specialized his playing in master workshops with Kenny Wheeler and Steve Lacy.
                    <br/> <br/>
                    One of his first bands in Berlin was the modern jazz quartet
                    "Open Sesame" (with Jacques Nobili, Robert Dörfert, and Jorgos Psirakis).

                    In 1993 he founded his jazz quartet "Takabanda" with Paul Schwingenschlögl
                    tp/fl, Jan von Klewitz saxes, and Akira Ando bass.

                    Several CD recordings and concert invitations led the musicians to festivals
                    in Italy, Greece, and Germany.

                    With the Takabanda-Ensemble and different guests, he created projects like:
                    "Takabanda plays Jackson Pollock" a video musical instant composition and
                    "Once upon a time in America" based on the film music of Ennio Morricone.
                    <br/> <br/>
                    He played and toured with the German chanson band "Wortfront" with
                    Sandra Kreisler and Roger Stein.

                    In 2010 he founded the duo MA.ORI with cellist Ulrich Maiss,
                    an improvisational composition project for drums/electronics and cello/electronics.

                    Paolo Eleodori has performed with the following jazz ensembles:
                    Gilad Atzmon & Orienthouse Ensemble, Klaus Doldinger's Passport,
                    Experimenti Berlin, Riccardo Fassi, and Jesse Ballard Band, among others.

                    In addition to that, Paolo Eleodori works regularly for various
                    theater productions and has contributed his diverse drumming and
                    percussion work to many different bands in the German world-music scene.

                    Since 2012 he has taught percussion and drums at the Global Music Academy in Berlin.

                    In 2020 he became the co-member and co-producer of the Luca Artioli Nuova Orchestra Italiana CD.
                    <br/><br/>
                    <strong>
                      Paolo Eleodori's CD productions:
                    </strong> <br/>
                    <span className="extra-info">
                      Tacabanda: "Ora imprecisa" <br/>
                      Takabanda: "La leggenda del pescatore" <br/>
                      Takabanda Extended: "Fructus spiritus" <br/>
                      New4Hands: "Kòre" <br/>
                      Awir quartet: "Back Home" <br/>
                      Ma.ori: "Vivi" <br/>
                      Jo Barthelmes Hipnosis: "Pasión o muerte" <br/>
                      Luca Artioli Nuova Orchestra Italiana: "Cinema e Poesia" <br/>
                    </span>
                    <br/>
                    <strong>
                      CD productions with Paolo Eleodori as a guest:
                    </strong> <br/>
                    <span className="extra-info">
                      Wortfront: "Von vorn mit Anlauf" <br/>
                      Wortfront: "Freilandherz" <br/> <br/>
                    </span>

                </p>
            </div>
        </>
    )
}

export default Hero
