import React from 'react'
import { FlexboxGrid, Nav } from 'rsuite'
import PhoneIcon from '@rsuite/icons/legacy/Mobile'
import Envelope from '@rsuite/icons/legacy/Envelope'
import { useMediaQuery } from './misc/custom-hooks'
import { Link } from 'react-router-dom'

const Footer = () => {
    const isMobile = useMediaQuery('(max-width: 992px)')
    return (
        <div className='bg-bordeaux padded-large'>

            <FlexboxGrid className='mt-4 padded ' justify='space-around' align='top'>
                <FlexboxGrid.Item colspan={isMobile ? 24 : 4}>
                    <h2 id='logo' className='text-white op-70'>
                        Paolo <br /> Eleodori
                    </h2>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={isMobile ? 24 : 4}>
                    <h4 className='text-white op-70'>
                        Contact information
                    </h4>
                    <Nav vertical>
                        <Nav.Item as='a' href='callto:+49 176 22936663'>
                            <PhoneIcon /> +49 176 22836663
                        </Nav.Item>
                        <Nav.Item as='a' href='mailto:paolo@eleodori.de'>
                            <Envelope /> paoloeleodori@gmail.com
                        </Nav.Item>
                    </Nav>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={isMobile ? 24 : 4}>
                    <h4 className='text-white op-70'>
                        Info
                    </h4>
                    <Nav vertical>
                        <Nav.Item as={Link} to='/about'>
                            About
                        </Nav.Item>
                        <Nav.Item as={Link} to='/projects'>
                            CDs
                        </Nav.Item>
                        <Nav.Item as={Link} to='/privacy-policy'>
                            Privacy policy
                        </Nav.Item>
                        <Nav.Item as={Link} to='/imprint'>
                            Imprint
                        </Nav.Item>
                    </Nav>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={isMobile ? 24 : 4}>
                    <h4 className='text-white op-70'>
                        Explore
                    </h4>
                    <Nav vertical>
                        <Nav.Item as={Link} to='/events'>
                            Events
                        </Nav.Item>
                        <Nav.Item as={Link} to='/gallery'>
                            Gallery
                        </Nav.Item>
                        <Nav.Item as={Link} to='/videos'>
                            Videos
                        </Nav.Item>
                    </Nav>
                </FlexboxGrid.Item>
            </FlexboxGrid>

        </div>
    )
}

export default Footer
