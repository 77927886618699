import React from 'react'
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'rsuite'
import { useMediaQuery } from './misc/custom-hooks';
import NavbarMenuModal from './NavbarMenuModal';

const NAVS = [
    {
        name: 'About',
        to: '/about'
    },
    {
        name: 'CDs',
        to: '/projects'
    },
    {
        name: 'Future Events',
        to: '/events'
    },
    {
        name: 'Gallery',
        to: '/gallery'
    },
    {
        name: 'Videos',
        to: '/videos'
    },
]

const CustomNavbar = ({ onSelect, activeKey, ...props }) => {
    const [active, setActive] = React.useState('paolo');
    const isMobile = useMediaQuery('(max-width: 992px)')

    return (
        <Navbar
            id='navbar'
            appearance='default' {...props}
            className='mw-100 align-center bg-grey z-5 shadow'
            active={active} onSelect={setActive}
        >
            <Navbar.Brand id='small-logo' className='text-white op-70 bg-bordeaux' href='/'>Paolo <br /> Eleodori</Navbar.Brand>
            {
                isMobile ? (
                    <NavbarMenuModal >
                        <Nav vertical className='w-100' >
                            {
                                NAVS.map(link => (
                                    <Nav.Item key={link.name}
                                        as={Link}
                                        to={link.to}
                                        className={`transition br-5 fs-1-5 w-100 ${window.location.pathname === link.to ? 'text-brown' : 'text-black-70'}`}
                                        onClick={() => {
                                            setActive(link.name)
                                        }}

                                    >
                                        {link.name}
                                    </Nav.Item>
                                ))
                            }
                        </Nav>
                    </NavbarMenuModal>
                ) : (
                    <Nav className='h-mc d-flex cg-5 justify-evenly' pullRight >
                      {/* {<Nav.Item key={'Admin'} as={Link} to={'/admin'}
                        className={`transition br-5 fs-1-5 ${window.location.pathname === '/admin' ? 'text-brown' : 'text-black-70'}`}
                        onClick={() => {
                            setActive('Admin')
                        }}
                        >
                          Admin
                        </Nav.Item> */}
                        {
                            NAVS.map(link => (
                                <Nav.Item key={link.name} as={Link} to={link.to}
                                    className={`transition br-5 fs-1-5 ${window.location.pathname === link.to ? 'text-brown' : 'text-black-70'}`}
                                    onClick={() => {
                                        setActive(link.name)
                                    }}
                                >
                                    {link.name}
                                </Nav.Item>
                            ))
                        }

                    </Nav>
                )
            }

        </Navbar>
    )
}

export default CustomNavbar
