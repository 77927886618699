import React from 'react'
import { FlexboxGrid, IconButton, Loader } from 'rsuite'
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem'
import PlayIcon from '@rsuite/icons/legacy/ArrowCircleRight';
import Events from '../home/components/Events';
import { useMediaQuery } from '../../components/misc/custom-hooks';
import { getMainEvent } from '../../Firebase';
import { Link } from 'react-router-dom';

const EventsPage = () => {
    const isMobile = useMediaQuery('(max-width: 992px)')
    const [mainEvent, setMainEvent] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    React.useEffect(() => {
      getMainEvent(setMainEvent, setLoading)
    }, [])
    return (
        <>
            <div className='vw-100 vh-100 d-flex align-center justify-content-center bg-bordeaux'>
                <div
                    style={{ width: 90 + '%', height: 500, borderRadius: 15 }}
                    className='bg-bordeaux'
                >
                  {loading ? (<Loader content='One moment please' size='lg' speed='slow' vertical/>) :
                  mainEvent === null ? null : (
                    <FlexboxGrid className='h-100 d-flex align-center'>
                        <FlexboxGridItem colspan={isMobile ? 24 : 12} className='padded-large d-flex flex-column justify-content-center text-white'>
                            <h1 className={`${isMobile ? 'fs-3' : ''}`} >{mainEvent.name}</h1>
                            <h3 className={`${isMobile ? 'fs-1-5' : ''} mt-4`}>{mainEvent.description}</h3>
                            <IconButton block={isMobile} style={isMobile ? { width: 100 + '%' } : { width: 200 }}
                            icon={<PlayIcon />} size='lg' placement="right" className='mt-5 text-black'
                            as={Link} to={`/events/${mainEvent.id}`}>
                                Read more
                            </IconButton>
                        </FlexboxGridItem>
                        <FlexboxGridItem colspan={isMobile ? 24 : 12} className='d-flex align-center justify-content-center'>
                            <img src={mainEvent.image_link} alt="Placeholder" width={isMobile ? 100 + '%' : 100 + '%'} height={75 + '%'} className='shadow' />
                        </FlexboxGridItem>
                    </FlexboxGrid>)}
                </div>
            </div>
            <Events isMobile={isMobile} />
        </>
    )
}

export default EventsPage
