import React from 'react'
import { useMediaQuery } from '../../components/misc/custom-hooks'
import GridArea from '../../components/GridArea'
import Hero from './components/Hero'

const About = () => {
  const isMobile = useMediaQuery('(max-width: 992px)')
  return (
    <>
      <Hero isMobile={isMobile} />
      <GridArea isMobile={isMobile} />
    </>
  )
}

export default About
