import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {get, getDatabase, ref, remove, set, update} from 'firebase/database'
import { Message, useToaster } from "rsuite";

const firebaseConfig = {
  apiKey: "AIzaSyDRwn2Pet8jVFRn9s3duj223qKgkDiJ-d0",
  authDomain: "paolo-eleodori.firebaseapp.com",
  projectId: "paolo-eleodori",
  storageBucket: "paolo-eleodori.appspot.com",
  messagingSenderId: "18421726299",
  appId: "1:18421726299:web:8dc41ebd5419933a44137e",
  measurementId: "G-5FR6WFMH3Q",
  databaseURL: 'https://paolo-eleodori-default-rtdb.europe-west1.firebasedatabase.app',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const database = getDatabase(app);

export const createOrder = (productId, name, mail, address, notes) => {
  const today = new Date(Date.now())
  const reference = ref(database, 'orders/' + today.getTime())
  set(reference, {
    id: today.getTime(),
    product_id: productId,
    customer_name: name,
    customer_email: mail,
    customer_address: address,
    extra_notes: notes,
    ordered_at: today.toDateString(),
    state: 'new',
  })
}

export const getOrders = async (setState, setLoading) => {
  if (setLoading !== null || setLoading !== undefined) {
    setLoading(true)
  }
  let data = []
  const reference = ref(database, 'orders/')
  await get(reference).then((snap) => {
    snap.forEach((item) => {
      data.push(item.val())
    })
  }).catch((err) => console.log(err.Message)).finally(() => {
    setState(data)
    if (setLoading !== null || setLoading !== undefined) {
      setLoading(false)
    }
  })
}
export const getCurrentOrder = (orderId, setState, setLoading) => {
  setLoading(true)
  const reference = ref(database, 'orders/' + orderId)
  get(reference).then((snap) => setState(snap.val())).catch((err) => console.log(err.Message)).finally(
    () => setLoading(false)
  )
}

export const createProject = (title, cover, des, songs, time, spotify, youtube, itunes, price) => {
  const today = new Date(Date.now())
  const reference = ref(database, 'projects/' + today.getTime())
  set(reference, {
    id: today.getTime(),
    title: title,
    cover: cover,
    description: des,
    songs: songs,
    time: time,
    spotify_link: spotify,
    youtube_link: youtube,
    itunes_link: itunes,
    price: price,
  })
}

export const getProjects = async (setState, setLoading) => {
  if (setLoading !== null || setLoading !== undefined) {
    setLoading(true)
  }
  const reference = ref(database, 'projects/')
  let data = []
  await get(reference).then((snap) => {
    snap.forEach((item) => {
      data.push(item.val())
    })
    setState(data)
  },).catch((err) => console.log(err.Message)).finally(() => {
    if (setLoading !== null || setLoading !== undefined) {
      setLoading(false)
    }
  })
}

export const getCurrentProject = async (projectId, setState, setLoading) => {
  if (setLoading !== null || setLoading !== undefined) {
    setLoading(true)
  }
  const reference = ref(database , 'projects/' + projectId)
  await get(reference).then((snap) => {
    setState(snap.val())
  }).catch((err) => console.log(err.Message)).finally(() => {
    if (setLoading !== null || setLoading !== undefined) {
      setLoading(false)
    }
  })
}

export const updateProject = async (projectId, updates, then, error, setSaving) => {
  setSaving(true)
  const reference = ref(database, 'projects/' + projectId)
  await update(reference, updates).then(then).catch(error).finally(() => setSaving(false))
}
export const deleteProject = async (projectId) => {
  const reference = ref(database, 'projects/' + projectId)
  const areYouSure = window.prompt('Are you sure?\nWrite "DELETE" to delete the project.')
  if (areYouSure === 'DELETE') {
    remove(reference).then(() => {
      useToaster().push(
        <Message showIcon type='success'>
          Project deleted succesfully
        </Message>, {placement: 'bottomCenter'}
      ); window.setTimeout(() => useToaster().clear(), 10000)
    }).catch((err) => {
      useToaster().push(
        <Message showIcon type='error'>
          {err.Message}
        </Message>, {placement: 'bottomCenter'}
      ); window.setTimeout(() => useToaster().clear(), 10000)
    })
  }
}

export const createEvent = async (name, date, time, intro, description, link, image, location, eventType,
  standardThen, error, mainThen) => {
  const today = new Date(Date.now())
  const reference = ref(database, 'events/' + today.getTime())
  const mainReference = ref(database, 'main_event')

    set(reference, {
      id: today.getTime(),
      name: name,
      short_description: intro,
      description: description,
      location: location,
      image_link: image,
      start_date: date.toDateString(),
      time: time,
      event_official_link: link,
    }).then(standardThen).catch(error)
    if (eventType === 'main') {
      let updates = {}
      updates['id'] = today.getTime()
      updates['name'] = name; updates['short_description'] = intro
      updates['description'] = description; updates['location'] = location
      updates['start_date'] = date.toDateString(); updates['time'] = time
      updates['event_official_link'] = link
      updates['image_link'] = image
      set(mainReference, updates).then(mainThen).catch(error)
    }
}

export const getAllEvents = async (setState, setLoading) => {
  if (setLoading !== null || setLoading !== undefined) {
    setLoading(true)
  }
  const reference = ref(database, 'events/')
  let data = []
  await get(reference).then((snap) => {
    snap.forEach((item) => {
      data.push(item.val())
    })
    setState(data)
  },).catch((err) => console.log(err.Message)).finally(() => {
    if (setLoading !== null || setLoading !== undefined) {
      setLoading(false)
    }
  })
}

export const getCurrentEvent = async (eventId, setState, setLoading) => {
  setLoading(true)
  const reference = ref(database, 'events/' + eventId)
  get(reference).then((snap) => setState(snap.val()))
  .catch((err) => console.log(err.Message))
  .finally(() => setLoading(false))
}
export const updateCurrentEvent = async (eventId, name, short_description, description,
  time, start_date, location, event_official_link, image_link, eventType, then, error, mainThen, setUpdating) => {
    setUpdating(true)
    const reference = ref(database, 'events/' + eventId)
    let updates = {}
    updates['name'] = name; updates['short_description'] = short_description;
    updates['description'] = description; updates['time'] = time;
    updates['start_date'] = start_date; updates['location'] = location;
    updates['event_official_link'] = event_official_link; updates['image_link'] = image_link;
    update(reference, updates).then(then).catch(error).finally(() => {
      if (eventType === 'standard') {
        setUpdating(false)
      }
    })
    if (eventType === 'main') {
      const reference = ref(database, 'main_event')
      update(reference, updates).then(mainThen).catch(error)
      .finally(() => setUpdating(false))
    }
}

export const deleteCurrentEvent = async (eventId, then, error) => {
  const reference = ref(database, 'events/' + eventId)
  remove(reference).then(then).catch(error)
}

export const getMainEvent = async (setState, setLoading) => {
  setLoading(true); const reference = ref(database, 'main_event');
  get(reference).then((snap) => setState(snap.val())).catch((err) => console.log(err.Message))
  .finally(() => setLoading(false))
}
