import React from 'react'
import { FlexboxGrid } from 'rsuite'

const Hero = ({ isMobile }) => {

    return (
        <FlexboxGrid id='home-hero' align='middle' justify='center' className='vh-100 vw-100'>
            <FlexboxGrid.Item>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    )
}

export default Hero