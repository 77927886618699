import React from 'react'
import PAOLO from '../../components/images/takabanda_team.svg'
import { useMediaQuery } from '../../components/misc/custom-hooks'
import '../../styles/videos.scss'

const VIDEOLINKS = [
    {
        url: "https://www.youtube.com/embed/Cflh9Y1-GOE",
        title: "CINEMA e POESIA - Live trailer"
    },
    {
        url: "https://www.youtube.com/embed/Zz3pGfA22GA",
        title: "Hipnosis im Peppi Guggenheim"
    },
    {
        url: "https://www.youtube.com/embed/ifOZGXqA4tY",
        title: "Shadow - live version"
    },
    {
        url: "https://www.youtube.com/embed/4WKjSikgmsc",
        title: "Kore - live Auditorium L'Aquilla"
    },
    {
        url: "https://www.youtube.com/embed/sgriw0Rpl44",
        title: "WHERE by New4Hands"
    },
    {
        url: "https://www.youtube.com/embed/ys7-OsHyjXM",
        title: "Nel cielo di molto lontano ft. Regis Molina"
    },
    {
        url: "https://www.youtube.com/embed/jqrybWpHmj4",
        title: "Awir Quartet studio trailer"
    },
    /*{
        url: "https://www.youtube.com/embed/o9Z0VvJvHaI",
        title: "Francesco Di Cicco blues quartet live at Brunello"
    },*/
    {
        url: "https://www.youtube.com/embed/38Jt2rguUQ8",
        title: "Takabanda plays Jackson Pollock"
    },
]

const VideosPage = () => {
    const isMobile = useMediaQuery('(max-width: 992px)')
    return (
        <>
            <div className='vw-100 pt-5 video-page-hero bg-grey'>
                <img src={PAOLO} alt="Paolo Eleodori holding a hi-hat in his left eye, making eye contact as if it is a good friend" />
            </div>
            <div className='bg-grey padded-large'>
                <h1 className='mt-5 mb-4 text-center' >
                    Videos
                </h1>
                <div className='mt-3'>

                    {
                        VIDEOLINKS.map(video => (
                            <div className='d-flex flex-column justify-content-center align-center padded-large'>
                                <h1 className='fs-1-5'>
                                    {video.title}
                                </h1>
                                <iframe
                                    width={90 + '%'} height={`${isMobile ? 'auto' : 550}`}
                                    src={video.url}
                                    title="2020-07-31 Johannes Barthelmes Hipnosis im Peppi Guggenheim m2 4cam"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen="true"
                                />

                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default VideosPage
