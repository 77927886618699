import React from 'react'
import { Button, Drawer, IconButton } from 'rsuite'
import { useModalState } from './misc/custom-hooks'
import MoreIcon from '@rsuite/icons/legacy/More'

const NavbarMenuModal = ({ children }) => {
    const { isOpen, open, close } = useModalState()
    return (
        <div className='d-flex align-center fl-r' style={{ height: 56 }}>
            <IconButton circle onClick={open} size='lg' icon={<MoreIcon />} />
            <Drawer open={isOpen} onClose={close} size="full" >
                <Drawer.Header>
                    <Drawer.Title>
                        Menu
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    {children}
                    <Button block onClick={close}>
                        Close
                    </Button>
                </Drawer.Body>
                <Drawer.Actions>
                    Actions
                </Drawer.Actions>
            </Drawer>
        </div>
    )
}

export default NavbarMenuModal