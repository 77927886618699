import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container, Content, Footer, Header } from 'rsuite'
import CustomNavbar from '../../components/CustomNavbar'
import PageFooter from '../../components/Footer'

const MainLayout = () => {
    return (
        <>
            <Container className='w-100' id="page-wrap">
                <Header>
                    <CustomNavbar />
                </Header>
                <Content>
                    <Outlet/>
                </Content>
                <Footer>
                    <PageFooter />
                </Footer>
            </Container>
        </>
    )
}

export default MainLayout
