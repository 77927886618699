import React from 'react'
import { Button, Col, FlexboxGrid, Grid, Loader, Row } from 'rsuite'
import Location from '@rsuite/icons/Location'
import Time from '@rsuite/icons/TimeRound'
import { Link } from 'react-router-dom'
import { getAllEvents } from '../../../Firebase'
import '../../../styles/home.scss'



const Events = ({ isMobile }) => {
  const [events, setEvents] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    getAllEvents(setEvents, setLoading)
  }, [])
    return (
        <div className='bg-grey'>
            <Grid className='p-page responsive-container' fluid >
                <Row>
                    <Col lg={24} md={24} xs={24}>
                        <h2 className=''>
                            Future Events
                        </h2>
                        <p>
                            Let us meet at the next event
                        </p>
                    </Col>
                </Row>

                <FlexboxGrid justify='space-between' className='mt-5'>
                    { loading ? (<Loader content='One moment please' size='md' speed='fast' vertical/>) :
                    events === null ? null : (
                        events.map((event) => (
                                <FlexboxGrid.Item colspan={isMobile ? 24 : 12} className='mt-5' key={event.id}>
                                    <FlexboxGrid>
                                        <FlexboxGrid.Item colspan={10}>
                                            <img src={event.image_link} alt={event.name} width={100 + '%'} height={250} className='shadow' />
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={14} className='padded'>
                                            <h3>
                                                {event.name}
                                            </h3>
                                            <h6>
                                                <Location /> {event.location}
                                            </h6>
                                            <p className='text-small mt-1 lh-1-5'>
                                                <Time /> {event.time}
                                            </p>
                                            <p className='mt-3 lh-1-5'>
                                                {event.short_description}
                                            </p>
                                            <Button as={Link} to={`/events/${event.id}`} appearance='ghost' color='red' className='mt-4' >
                                                Read more
                                            </Button>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </FlexboxGrid.Item>
                        )))
                    }
                </FlexboxGrid>
            </Grid>
        </div>
    )
}

export default Events
