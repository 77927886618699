import React from 'react'
import PLACEHOLDER from '../../components/images/placeholder.jpg'
import { Button, Loader } from 'rsuite';
import MusicBtns from './components/MusicBtns';
import { useMediaQuery } from '../../components/misc/custom-hooks';
import { getProjects } from '../../Firebase';
import '../../styles/project.scss'

// Fore testing
export const SLIDES = [
    {
        index: 0,
        price: 35,
        title: "Slide 1",
        songs: ['song1', 'song2', 'song3', 'song4', 'song5', 'song6', 'song7'],
        source: PLACEHOLDER,
        time: 107,
        des: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore minima eos illum vero. Harum laboriosam quibusdam necessitatibus corporis, dignissimos ea deserunt rem error similique quos?"
    },
    {
        index: 1,
        price: 42,
        title: "Slide 2",
        songs: ['song1', 'song2', 'song3', 'song4', 'song5', ],
        source: PLACEHOLDER,
        time: 82,
        des: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore minima eos illum vero. Harum laboriosam quibusdam necessitatibus corporis, dignissimos ea deserunt rem error similique quos?"
    },
    {
        index: 2,
        price: 55,
        title: "Slide 3",
        songs: ['song1', 'song2', 'song3', 'song4', 'song5', 'song6', ],
        source: PLACEHOLDER,
        time: 95,
        des: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore minima eos illum vero. Harum laboriosam quibusdam necessitatibus corporis, dignissimos ea deserunt rem error similique quos?"
    },
    {
        index: 3,
        price: 32,
        title: "Slide 4",
        songs: ['song1', 'song2', 'song3',],
        source: PLACEHOLDER,
        time: 20,
        des: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Labore minima eos illum vero. Harum laboriosam quibusdam necessitatibus corporis, dignissimos ea deserunt rem error similique quos?"
    },
];

const ProjectsPage = () => {
    const isMobile = useMediaQuery('(max-width: 992px)')
    const [loading, setLoading] = React.useState(true)
    const [projects, setProjects] = React.useState(null)

    React.useEffect(() => {
      getProjects(setProjects, setLoading)
    }, [])
    return (
        <>
            <div className='project-section'>
              {
                loading ? (
                  <Loader size='lg' speed='slow' content='One moment please.' />
                ) : projects === null ? null : (
                  <div className='project-slider'>
                    {
                        projects.map((project, index) => (
                            <div className='project-item' id={`project-${index}`}>
                                <div className='inner-item'>
                                    <img src={project.cover} alt={project.title} className='cover-img' />
                                    <div className='overlay'>
                                        <h1 className={`text-white mb-2 ${isMobile ? 'text-md' : ''} title`}>
                                            {project.title}
                                        </h1>
                                        <p className='text-white description'>
                                            {project.description}
                                        </p>
                                        <p className="text-white price">{project.price}€</p>
                                        <MusicBtns isMobile={isMobile} project={project} />
                                        {
                                          isMobile ? null : (
                                            <div className="toolbar">
                                          {index > 0 ? (
                                          <Button
                                          className='nav-btn' as={'a'} href={`#project-${index - 1}`} appearance='link'>
                                          Previous
                                          </Button>
                                          ) : null}
                                          {index + 1 >= projects.length ? null : (
                                          <Button
                                          className='nav-btn' as={'a'} href={`#project-${index + 1}`} appearance='link'>
                                            Next
                                          </Button> ) }
                                        </div>
                                          )
                                        }

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                )
              }
            </div>
        </>
    )
}

export default ProjectsPage
