import React from 'react'
import GridArea from '../../components/GridArea'

const GalleryPage = () => {
    return (
        <>
            <div className='padded-large mt-5 bg-bordeaux'>

                <h1 className='mt-5 text-white'>
                    Gallery
                </h1>
            </div>
            <GridArea />
        </>
    )
}

export default GalleryPage
