import React from 'react'
import { ButtonToolbar, IconButton } from 'rsuite'
import Spotify from '@rsuite/icons/legacy/Spotify'
import Youtube from '@rsuite/icons/legacy/YoutubePlay'
import AppleMusic from '@rsuite/icons/legacy/Apple'
import BUY from '@rsuite/icons/legacy/ShoppingBag'
import PurchaseModal from '../../../components/PurchaseModal'

const MusicBtns = ({ isMobile, project }) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)
    return (
        <>
            <p className={`${isMobile ? 'text-center' : ''} text-white`}>Listen on:</p>
            <ButtonToolbar className={`mt-1 ${isMobile ? 'd-flex justify-content-center' : ''} `}>
              {
                project.spotify_link !== "" ? (
                  <IconButton
                  as='a' href={project.spotify_link}
                  target='_blank' rel='norefferer'
                  color='green'
                  appearance='primary'
                  icon={<Spotify />}
                  size={isMobile ? 'sm' : 'lg'}
                  />
                ) : null
              }
              {
                project.youtube_link !== "" ? (
                  <IconButton
                  as='a' href={project.youtube_link}
                  target='_blank' rel='norefferer'
                  color='red'
                  appearance='primary'
                  icon={<Youtube />}
                  size={isMobile ? 'sm' : 'lg'}
                  />
                ) : null
              }
              {
                project.itunes_link !== "" ? (
                  <IconButton
                  as='a' href={project.itunes_link}
                  target='_blank' rel='norefferer'
                  color='violet'
                  style={{backgroundColor: 'rgb(30,30,30)'}}
                  appearance='primary'
                  icon={<AppleMusic />}
                  size={isMobile ? 'sm' : 'lg'}
                  />
                ) : null
              }
                <IconButton
                color='blue'
                appearance='primary'
                icon={<BUY />}
                size={isMobile ? 'sm' : 'lg'}
                onClick={openModal}
                />
            </ButtonToolbar>
            <PurchaseModal open={modalOpen} close={closeModal} project={project}/>
        </>
    )
}

export default MusicBtns
