import React from 'react'
import NOALY from '../../assets/noaly-logo.svg'

const ImprintPage = () => {
  return (
    <div className="privacy-policy-page">
      <h1 className="title">Imprint</h1>
      <div>
		<span class="font_bold">Angaben gemäß $ 5 TMG:</span><br/>
		Paolo Eleodori<br/>
		Crellestr. 44<br/>
		10827 Berlin<br/>
        <div class="luecke14">&nbsp;</div>
		Telefon: 0049-(0)30/612 22 84<br/>
		Mobil: 0049-(0)176-22 83 666 3<br/>
		E-Mail: <span class="nav"><a href="mailto:info@paoloeleodori.com">info@paoloeleodori.com</a></span><br/>
		Umsatzsteuer-ID: 18/275/52964<br/>
        <div class="luecke14">&nbsp;</div>
		Quelle: Impressum-Generator von e-recht24.de für Einzelunternehmer.<br/>

		<div class="luecke30">&nbsp;</div>

		<span class="font_bold">W3C zertifiziert</span><br/>
		<span>
		<a href="http://validator.w3.org/check?uri=http%3A%2F%2Fwww.paoloeleodori.com%2F" target="_blank" rel="noreferrer">
		<img src="images/icons/icon_html5.png"  class="floatleft img_mouseover" alt=""/>
    </a>
		<a href="http://jigsaw.w3.org/css-validator/validator?uri=http%3A%2F%2Fwww.paoloeleodori.com%2F&amp;profile=css3" target="_blank" rel="noreferrer">
		<img src="images/icons/icon_css3.png"  class="floatleft img_mouseover" alt=""/></a>
		Diese Webpräsenz wurde vom World Wide Web Consortium (W3C) als dem HTML5-Standard, sowie dem CSS3-Level entsprechend zertifiziert.
		<br/>
		Mehr Informationen über das World Wide Web Consortium (kurz: W3C) erfahren sie <a href="http://de.wikipedia.org/wiki/World_Wide_Web_Consortium" target="_blank" rel="noreferrer">hier</a>.
		</span>

        <div class="luecke30">&nbsp;</div>

		<span class="font_bold">Konzeption und Realisierung</span><br/>

		<div >
			<div >
			<div >
			Developer: Brian Hansen<br/>
      Designer: Brian Hansen <br/>
			<a href="https://bold.pro/my/brian-tembo-hansen" target="_blank" rel="noreferrer">Portfolio</a> <br/> <br/>
      Company: Noaly <br/>
      Copenhagen, Denmark <br/>
      <a href="https://noaly.netlify.app/" target="_blank" rel="noreferrer">Noaly DK</a> <br/>
			</div>
			<div >
				<div id="noaly_logo" >
    			  <img src={NOALY} alt="Noaly A/S logo" width={250} height={250} />
          </div>
			</div>
			</div>
		</div>


		<div class="luecke30">&nbsp;</div>

		<h6>Haftungsausschluss:</h6> <br/>
		<span class="font_bold">Haftung für Inhalte</span>
		<br/>
		Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch
		keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
		verantwortlich.	Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
		oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
		allgemeinen Gesetzen bleiben hier von unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
		Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        <div class="luecke20">&nbsp;</div>
		<span class="font_bold">Haftung für Links</span>
		<br/>
		Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine
		Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden
		zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
		Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
		Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        <div class="luecke20">&nbsp;</div>
		<span class="font_bold">Urheberrecht</span>
		<br/>
		Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und
		jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
		dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
		Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
		bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
</div>
    </div>
  )
}

export default ImprintPage
