import React from 'react'
import { Link } from 'react-router-dom'
import { Button, FlexboxGrid } from 'rsuite'
import PAOLO from '../../../components/images/Maybe.jpg'


const Bio = ({ isMobile }) => {
    return (
        <div className={`${isMobile ? 'padded' : 'padded-large p-50 p-page'} bg-bordeaux`}>
            <FlexboxGrid className={`${isMobile ? 'padded' : 'p-50'} `} align='middle' justify='space-around'>
                <FlexboxGrid.Item colspan={isMobile ? 24 : 6} order={isMobile ? 1 : 2} className={`${isMobile ? 'mt-3' : ''}`}>
                    <img src={PAOLO} alt="Placeholder" width={100 + '%'} height={600} className='shadow' />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={isMobile ? 24 : 16}>
                    <h1 className={`${isMobile ? 'fs-1-5' : ''} 'text-white op-70'`} >
                        Biography
                    </h1>
                    <h3 className='mt-1 text-light-brown'>
                        About Paolo
                    </h3>
                    <p className='text-white op-70 mt-3 about-txt' >
                    Born in 1965 in Pisa, he started playing drums at the age of 14,
                    initially as an autodidact, later he received lessons from Carlo Cavallini in Livorno.

                    In 1987 he came to Berlin,
                    where he continued his percussion studies with Michael Landmesser Sdrena
                    and specialized his playing in master workshops with Kenny Wheeler and Steve Lacy.
                    <br/> <br/>
                    One of his first bands in Berlin was the modern jazz quartet
                    "Open Sesame" (with Jacques Nobili, Robert Dörfert, and Jorgos Psirakis).

                    In 1993 he founded his jazz quartet "Takabanda" with Paul Schwingenschlögl
                    tp/fl, Jan von Klewitz saxes, and Akira Ando bass.

                    Several CD recordings and concert invitations led the musicians to festivals
                    in Italy, Greece, and Germany.

                    With the Takabanda-Ensemble and different guests, he created projects like:
                    "Takabanda plays Jackson Pollock" a video musical instant composition and
                    "Once upon a time in America" based on the film music of Ennio Morricone.
                    </p>
                    <Button appearance='ghost' color='orange' block={isMobile} as={Link} to='/about' className='mt-2'>
                        Read more
                    </Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div >
    )
}

export default Bio
