import React from 'react'
import { Row, Grid, Modal } from 'rsuite'
import IMG1 from './images/_MG_4760.jpg'
import IMG2 from '../assets/paolopiatto.jpg'
import IMG3 from './images/Maybe.jpg'
import IMG4 from './images/IMG_1214.jpg'
import IMG5 from './images/Nuova_Orchestra_Italiana.jpg'
import IMG6 from './images/Paolo_Eleodori.jpg'
import IMG7 from '../assets/Paolo-Ulrich01.jpg'
import IMG8 from '../assets/Paolo-Ulrich02.jpg'
import GridElement from './GridElement'
import { useState } from 'react'
import ModalHeader from 'rsuite/esm/Modal/ModalHeader'
import ImageModalBody from './ImageModal'



const GridArea = ({ isMobile }) => {
    const [isOpen, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [index, setIndex] = useState(0)

    const openModal = () => {
        setOpen(true)
        setLoading(true)
    }
    const closeModal = () => {
        setOpen(false)
    }
    function enterModal(x) {
      setIndex(x)
      setTimeout(() => setLoading(false), 1500)
    }
    function makeCurrent(x) {
      setIndex(x)
    }
    const goLeft = () => index >= 1 && setIndex(index - 1)
    const goRight = () => index < 7 && setIndex(index + 1)

    return (
        <>
            <Grid className={`vw-100 ${isMobile ? 'padded' : 'p-50'} bg-bordeaux`}>
                <Row>
                    <GridElement lg={14} md={16} img={IMG1} open={openModal} index={0} makeCurrent={makeCurrent} />
                    <GridElement lg={10} md={8} img={IMG2} open={openModal} index={1} makeCurrent={makeCurrent} />
                </Row>
                <Row >
                    <GridElement lg={8} md={8} img={IMG3} open={openModal} index={2} makeCurrent={makeCurrent} />
                    <GridElement lg={16} md={16} img={IMG4} open={openModal} index={3} makeCurrent={makeCurrent} />
                </Row>
                <Row >
                    <GridElement lg={16} md={16} img={IMG5} open={openModal} index={4} makeCurrent={makeCurrent} />
                    <GridElement lg={8} md={8} img={IMG6} open={openModal} index={5} makeCurrent={makeCurrent} />
                </Row>
                <Row >
                    <GridElement lg={12} md={12} img={IMG7} open={openModal} index={6} makeCurrent={makeCurrent} />
                    {/*<GridElement lg={12} md={12} img={IMG8} open={openModal} index={7} makeCurrent={makeCurrent} />*/}
                </Row>
            </Grid>
            <Modal open={isOpen} onClose={closeModal} onEntered={() => enterModal(index)} size='lg'>
                <ModalHeader></ModalHeader>
                <ImageModalBody index={index} goLeft={goLeft} goRight={goRight} loading={loading} />
            </Modal>
        </>
    )
}

export default GridArea
