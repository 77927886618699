import React from 'react'
import '../../styles/privacy-policy.scss'

const PrivacyPolicy = () => {
  return (
      <div className="privacy-policy-page">
        <h1 className="title">Privacy policy</h1>
        <div className='content'>
<p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
<p>Paolo Eleodori (weitere Informationen auf der Seite Impressum)</p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">Ihre Betroffenenrechte</h4>
<p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
<ul>
<li>- Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,</li>
<li>- Berichtigung unrichtiger personenbezogener Daten,</li>
<li>- Löschung Ihrer bei uns gespeicherten Daten,</li>
<li>- Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,</li>
<li>- Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und</li>
<li>- Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben.</li>
</ul>
<p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
<p>Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="noreferrer">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte</h4>
<p>Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
<ul>
<li>- Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
<li>- die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,</li>
<li>- die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,</li>
</ul>
<p>die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.</p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">Löschung bzw. Sperrung der Daten</h4>
<p>Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur so lange, wie dies zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.</p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">Erfassung allgemeiner Informationen beim Besuch unserer Website</h4>
<p>Wenn Sie auf unsere Website zugreifen, werden automatisch mittels eines Cookies Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers und ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre Person zulassen.</p>
<p>Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
<ul>
<li>- Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
<li>- Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
<li>- Auswertung der Systemsicherheit und -stabilität sowie</li>
<li>- zu weiteren administrativen Zwecken.</li>
</ul>
<p>Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem berechtigten Interesse aus den vorgenannten Zwecken zur Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Empfänger der Daten sind nur die verantwortliche Stelle und ggf. Auftragsverarbeiter.</p>
<p>Anonyme Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.</p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">Erbringung kostenpflichtiger Leistungen</h4>
<p>Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten erfragt, wie z.B. Zahlungsangaben, um Ihre Bestellung ausführen zu können. Wir speichern diese Daten in unseren Systemen bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind.</p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">SSL-Verschlüsselung</h4>
<p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">Kontaktformular</h4>
<p>Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular mit uns in Kontakt, erteilen Sie uns zum Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür ist die Angabe einer validen E-Mail-Adresse und eines Namen erforderlich. Diese Informationen dienen der Zuordnung Ihrer Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional. Die von Ihnen gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage werden personenbezogene Daten automatisch gelöscht.</p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">Sound &amp; CD's Bestellformular</h4>
<p>Bei einer Nutzung unseres Bestellformulars, erteilen Sie uns zum Zwecke der Lieferung und der Kontaktaufnahme Ihre freiwillige Einwilligung zur Nutzung dieser Daten unsererseits.
Für die Bestellung einer CD benötigen wir als Angabe ihre Postadresse für die Lieferung, als auch Ihre Emailadresse zum Zwecke der Kommunikation.
Die von Ihnen gemachten Angaben werden zum Zwecke der Abwicklung der Bestellung sowie für steuerliche Abrechnung gespeichert.
Eine Weiterverwendung oder auch Weitergabe an Dritte dieser Daten findet nicht statt.
Wenn aus steuerlicher Sicht zulässig, werden alle nichtbenötigten personenbezogenen Daten zeitnah und automatisch von uns gelöscht.</p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">Verwendung von Scriptbibliotheken (Google Webfonts)</h4>
<p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website Scriptbibliotheken und Schriftbibliotheken wie z. B. Google Webfonts (<a href="http://www.google.com/webfonts/" target="_blank" rel="noreferrer">https://www.google.com/webfonts/</a>). Google Webfonts werden zur Vermeidung mehrfachen Ladens in den Cache Ihres Browsers übertragen. Falls der Browser die Google Webfonts nicht unterstützt oder den Zugriff unterbindet, werden Inhalte in einer Standardschrift angezeigt.</p>
<p>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken – dass Betreiber entsprechender Bibliotheken Daten erheben.</p>
<p>Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">https://www.google.com/policies/privacy/</a></p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">Eingebettete YouTube-Videos</h4>
<p>Auf einigen unserer Webseiten betten wir Youtube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von Youtube hergestellt. Dabei wird Youtube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem Youtube-Account eingeloggt sind, kann Youtube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem Youtube-Account ausloggen.</p>
<p>Wird ein Youtube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln.</p>
<p>Wer das Speichern von Cookies für das Google-Ad-Programm deaktiviert hat, wird auch beim Anschauen von Youtube-Videos mit keinen solchen Cookies rechnen müssen. Youtube legt aber auch in anderen Cookies nicht-personenbezogene Nutzungsinformationen ab. Möchten Sie dies verhindern, so müssen Sie das Speichern von Cookies im Browser blockieren.</p>
<p>Weitere Informationen zum Datenschutz bei „Youtube“ finden Sie in der Datenschutzerklärung des Anbieters unter: <a href="https://www.google.de/intl/de/policies/privacy/" target="_blank" rel="noreferrer">https://www.google.de/intl/de/policies/privacy/ </a></p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">Änderung unserer Datenschutzbestimmungen</h4>
<p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>

<div className="luecke20">&nbsp;</div>

<h4 className="info-title">Fragen an den Datenschutzbeauftragten</h4>
<p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation (s.o.).</p>
<p><em>Die Datenschutzerklärung wurde mit dem </em><a href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/" target="_blank" rel="noreferrer"><em>Datenschutzerklärungs-Generator der activeMind AG erstellt</em></a><em>.</em></p>

</div>
      </div>
  )
}

export default PrivacyPolicy
